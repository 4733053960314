<template>
  <div class="flex flex-col h-full">
    <div class="md:hidden px-6 h-16 flex items-center flex-none">
      <img src="/img/logo.svg" />
    </div>
    <div class="hidden md:block fixed left-6 md:left-9 md:top-8 top-4 h-full">
      <img src="/img/logo.svg" />
    </div>
    <div class="flex h-full">
      <div class="md:flex flex-1 hidden justify-center items-center h-full">
        <img src="/img/splash.svg" />
      </div>
      <div
        class="bg-white px-6 pb-6 md:pb-0 md:px-0 flex-1 h-full flex flex-col justify-center items-center sm:bg-indigo-50"
      >
        <slot />
      </div>
    </div>
  </div>
</template>
